<template>
  <CSidebar
    aside
    :show="$store.state.selectNewImageAsideShow"
    @update:show="(val) => $store.commit('set', ['selectNewImageAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'selectNewImageAsideShow')" />

    <CListGroup class="scrollable list-group-accent mt-5">
      <CListGroupItem class="mb-0 list-group-item-accent-primary bg-light text-center font-weight-bold text-uppercase">
        SELECT NEW IMAGE
      </CListGroupItem>

      <CListGroupItem class="list-group-item-accent-secondary">
        <!-- Tag Filter -->
        <v-select
          class="v-select-filter"
          placeholder="Tags.."
          v-model="tags"
          :options="tagList"
          :reduce="t => t.id"
          label="name"
          multiple
          :searchable="false"
          @input="getAllItems"
        />

        <div class="image-container">
          <ul class="image-list">
            <li v-for="image in loadedItems" :key="image.id" @click="selectedImage = image" :class="{ 'selected': image === selectedImage || (!selectedImage && image.id == imageId)}">
              <img :src="image.image_url" />
            </li>
          </ul>
        </div>
      </CListGroupItem>

      <CListGroupItem class="sticky-bottom list-group-item-accent-primary bg-white">
        <CButton
          color="primary"
          :disabled="!selectedImage"
          @click="select"
        >
          Select
        </CButton>
      </CListGroupItem>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading"/>

  </CSidebar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewImage",
  props: {
    imageId: { type: [Number], default: null }
  },
  data() {
    return {
      loading: false,
      selectedImage: null,

      loadedItems: [],
      tagList: [],
      tags: [],
    };
  },

  watch: {
    async reloadParams() {
      this.loading = true;
      if (this.$store.state.selectNewImageAsideShow) {
        this.selectedImage = null;
        // await Promise.all([this.getTags(), this.getAllItems()]);
        await Promise.all([
          !this.tagList.length && this.getTags(),
          !this.loadedItems.length && this.getAllItems()
        ].filter(Boolean));
      }
      this.loading = false;
    },
  },

  computed: {
    ...mapGetters(["errors"]),

    reloadParams() {
      return [this.$store.state.selectNewImageAsideShow];
    }
  },

  methods: {
    async getTags() {
      await this.$axios
        .get(this.$backend.IMAGES.TAGS, {
          params: { type: 'category' },
        })
        .then((response) => {
          this.tagList = response.data;
        });
    },

    async getAllItems() {
      var url = new URL(this.$backend.IMAGES.GET_ALL),
        params = {
          type: 'category',
          tags: this.tags,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then(({data}) => {
          this.loadedItems = data;
        });
    },

    select() {
      this.$emit('update', this.selectedImage);
      this.$store.commit('set', ['selectNewImageAsideShow', false]);
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}

.image-container {
  margin-top: 20px;
}
.image-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.image-list li {
  margin: 5px 0;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.image-list li:hover {
  transform: scale(1.05);
}
.image-list li.selected {
  padding: 0.25rem;
  outline: 3px solid #3498db;
  border-radius: 5px;
}
.image-list img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}
</style>
