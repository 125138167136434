<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
        <template v-else>
          to <h3 class="d-inline"><CBadge color="secondary">{{ this.restaurantName | uppercase }}</CBadge></h3>
          Restaurant
        </template>
      </h5>
      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'RestaurantLinkedCategories'}">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <CForm>
        <div>
          <CInput
            label="Name"
            placeholder="Name"
            :lazy="false"
            :value.sync="$v.form.category_name.$model"
            :isValid="checkIfValid('category_name')"
            invalidFeedback="This is a required field and must be at least 2 characters"
          />

          <CInput
            label="Note Public"
            placeholder="Note Public"
            :lazy="false"
            :value.sync="$v.form.note_public.$model"
          />

          <div class="form-group">
            <label class="d-block" style="font-weight: 500">Image</label>
            <div class="cat-image" v-if="previewImage">
              <img :src="previewImage" />
              <div class="action" @click="selectNewImage">Change</div>
            </div>
            <CButton v-else color="link" class="p-0" @click="selectNewImage">Add Image</CButton>
          </div>

          <div class="form-group w-100">
            <label class="d-block" style="font-weight: 500">Turn</label>
            <CSwitch
              labelOn="On"
              labelOff="Off"
              color="success"
              shape="pill"
              :lazy="false"
              :checked.sync="$v.form.eol.$model"
            />
          </div>

          <div class="form-group w-100">
            <label class="d-block">Valid Days *</label>
            <div class="day-group">
              <div v-for="(day, index) in days" class="form-check form-check-inline mr-1 mt-1">
                <input
                  class="form-check-input d-none"
                  type="checkbox"
                  :id="`day_${index}`"
                  :value="index"
                  v-model="$v.form.valid_days_of_week.$model"
                />
                <label class="form-check-label" :for="`day_${index}`">{{ day }}</label>
              </div>
            </div>
            <small>* All days are valid if none is selected</small>
          </div>
        </div>
      </CForm>
    </CCardBody>

    <CCardFooter>
      <CButton
        color="primary"
        :disabled="submitted"
        @click="isEdit ? update() : store()"
      >
        Submit
      </CButton>
    </CCardFooter>

    <CElementCover :opacity="0.4" v-show="submitted" />

    <SelectImage :imageId="form.image_id" @update="selectedNewImage"/>
  </CCard>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"
import SelectImage from "./SelectImage"

export default {
  name: 'LinkedCategoryItem',
  components: {
    SelectImage,
  },
  data() {
    return {
      form: {},
      isEdit: false,
      submitted: false,

      itemName: '',
      restaurantName: "",
      previewImage: null,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      days: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    }
  },

  created () {
    if(this.itemId) {
      this.form = this.getForm(this.itemId)
      this.isEdit = true
    }
    else
      this.form = this.getForm()
  },

  mounted() {
    this.getRestaurantName();
  },

  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    restaurantId() {
      return this.$route.params.restaurant_id;
    },
    itemId() {
      return this.$route.params.id
    }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      category_name: {
        required,
        minLength: minLength(2)
      },
      note_public: { },
      eol: { },
      valid_days_of_week: { },
      image_id: { },
    },
  },

  methods: {
    getRestaurantName() {
      this.$axios
        .get(this.$backend.RESTAURANT.GET_NAME.replace("{id}", this.restaurantId))
        .then((response) => {
          this.restaurantName = response.data.restaurant_name;
        })
        .catch((error) => { this.restaurantName = 'Not Found' })
    },

    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    store () {
      if (this.isValid) {
        this.submitted = true
        // -(1)- Form Bilgilerini Kaydetme
        this.$axios.post(this.$backend.RESTAURANT_LINKED_CATEGORIES.STORE.replace("{restaurant_id}", this.restaurantId), this.form)
          .then((response)=>{
            this.successAlertMessage = {itemName: response.data.category_name, message: 'linked category added.'}
            this.dismissSuccessAlert = this.dismissSecs
            this.reset();
          })
          .catch((error)=>{
            this.errorAlertMessage = error.response.data
            this.showErrorAlert = true
            this.submitted = false
          })
      } // -(1)- Form Bilgilerini Kaydetme / end
      else
       this.validate()
    },

    update () {
      if (this.isValid) {
        this.submitted = true
        // -(1)- Form Bilgilerini Güncelleme
        this.$axios.put(this.$backend.RESTAURANT_LINKED_CATEGORIES.UPDATE.replace("{restaurant_id}", this.restaurantId).replace("{id}", this.itemId), this.form)
          .then((response)=>{
            this.itemName = response.data.category_name
            this.successAlertMessage = {itemName: this.itemName, message: 'linked category updated.'}
            this.dismissSuccessAlert = this.dismissSecs
            this.showErrorAlert = false
          })
          .catch((error)=>{
            this.errorAlertMessage = error.response.data
            this.showErrorAlert = true
          })
          .finally(() => {
            this.submitted = false
          });
      } // -(1)- Form Bilgilerini Güncelleme / end
      else
       this.validate()
    },

    validate () {
      this.$v.$touch()
    },

    reset () {
      this.form = this.getForm()
      this.submitted = false
      this.showErrorAlert = false
      this.$v.$reset()
    },

    getForm (id = null) {
      var form = {
        category_name: "",
        note_public: "",
        eol: true,
        restaurant_id: this.restaurantId,
        internNotes: '',
        valid_days_of_week: [],
        image_id: null
      };

      if(id) {
        this.$axios
          .get(
            this.$backend.RESTAURANT_LINKED_CATEGORIES.SHOW.replace("{restaurant_id}", this.restaurantId).replace("{id}", id)
          )
          .then((res) => {
            form.category_name = res.data.category_name;
            form.note_public = res.data.note_public;
            form.eol = res.data.eol == 0;
            form.valid_days_of_week = res.data.valid_days_of_week ?? [];
            form.image_id = res.data.image_id;

            if(res.data.image) {
              this.previewImage = res.data.image.image_url;
            }

            this.itemName = res.data.category_name
          })
          .catch((error) => {
            this.$router.push({ name: 'RestaurantLinkedCategories' })
          })
      }
      return form;
    },

    selectNewImage() {
      this.$store.commit('set', ['selectNewImageAsideShow', true]);
    },
    selectedNewImage(image) {
      this.form.image_id = image.id;
      this.previewImage = image.image_url;
    },
  }
}
</script>


<style>
.day-group .form-check {
  padding-left: 0 !important;
}
.day-group .form-check-input {
  display: none;
}
.day-group .form-check-label {
  font-weight: normal;
  text-align: center;
  width: 32px;
  cursor: pointer;
  padding: 0.275rem;
  border: 1px solid rgba(60,60,60,.26);
  border-radius: 50%;
}
.day-group input[type=checkbox]:checked + .form-check-label {
  background-color: #d5f1de;
  border-color: #c4ebd1;
}

.cat-image {
  position: relative;
  display: inline-block;
}
.cat-image img {
  width: 500px;
  transition: all 0.4s ease-in-out;
  backface-visibility: hidden;
  border-radius: 12px;
  outline: 1px dotted #ccc;
}
.cat-image .action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  display: none;
}
.cat-image:hover img {
  opacity: 0.4;
}
.cat-image:hover .action {
  display: block;
}
</style>
